.formFileUpload {
    position: relative;
    flex-direction: column;

	height: 100%;
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
	border-style: dashed;
	border-color: #176AD6;
	border-width: 2px;
	border-radius: 8px;
}

.inputFileUpload {
	display: none;
}

.labelFileUpload {
	height: 100%;
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f8fafc;
}


.dragActive {
	background-color: #ffffff;
}

.fileUpload {
	cursor: pointer;
	padding: 0.25rem;
	font-size: 1rem;
	border: none;
	font-family: 'Oswald', sans-serif;
	background-color: transparent;
	color: #176AD6;
}

.fileUpload:hover {
	text-decoration-line: underline;
}


.dragFileElement {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

#file-upload-text {
	width: 85%;
}

#upload-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    
	margin-top: 20px;
	height: 26px;
	font-size: 15px;
	font-weight: 600;
}