@import "./styles/custom.scss";
@import "./styles/Sidebar.scss";
@import './styles/ProfilePage.scss';

@import "~bootstrap-icons/font/bootstrap-icons.css";


//elements in forms
.cancelButton {
	margin-left: 1rem;
}

//tables
.right-aligned-cell {
	text-align: right;
}

.fullWidthRow {
	width: 100%;
}

.logo {
	max-width: 48px;
	margin-right: 12px;
}

.modal-body {
    ul {
        padding-left: 0;
    }
    li {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.pdf_modal_wrapper { 
    overflow: auto;

    @media (max-width: 767px) {
        display: block;
        height: 38rem;
        margin-top: 9rem;
    }

    .modal-content {
        min-width: max-content;

        @media (max-width: 1024px) {
            min-width: auto
        }
    }

    .pdf_modal_body { 
        max-height: max-content;
        overflow: auto;

        .pdf_modal_nav {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .pdf_modal_btns {
                display: flex;
                gap: 1rem;
            }
        }
    }
   
}

.react-table-lite-header {
    span {
        span {
            cursor: pointer;
            font-size: 13px;
        }
    }

    .react-table-lite-cell {
        position: relative !important;
    }
}
