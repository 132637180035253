.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.unsupported-file-type {
  font-size: 0.875em; /* Smaller font size */
  color: #666; /* Grey color for the text */
  text-align: center;
  margin: auto; /* Center the text within its container */
  padding: 20px; /* Add some padding for spacing */
}

.custom-modal-content {
  position: relative;
  background: white;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 85%;
  height: 85%;
  overflow: auto; /* Changed from hidden to auto */
  margin: 0 12px;
}

.custom-modal-close {
  position: fixed;
  top: 15px; /* Adjusted for better centering */
  right: 15px;
  border: none;
  font-size: 2em; /* Adjusted size to ensure it fits within the circle */
  cursor: pointer;
  z-index: 1100; /* Ensure close button is above the modal */
  border-radius: 50%;
  background-color: whitesmoke;
  padding: 10px; /* Adjusted padding to ensure a fully round button */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-viewer {
  max-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px; /* Ensure margin on all sides */
  padding: 10px; /* Padding to ensure the image doesn't touch the edge */
  overflow-y: auto;
}

.image-viewer img {
  max-width: 100%;
  height: auto;
  display: block;
}
