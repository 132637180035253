.layout {
  display: flex;

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .main-wrapper {
    overflow: auto;
    min-height: 100vh;
    max-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .content-wrapper {
    width: 100%;
    // max-height: 92vh;

    padding: 10px 20px;

    .children-container {
      max-height: 91%;
      padding: 10px;

      #f-main {
        background-color: transparent;
        justify-content: center;

        & > div:nth-child(1) {
          flex-direction: row;
        }

      }

    }

    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .header {
    background-color: rgba(227, 232, 235, 0.5);
    box-shadow: 0 0 10px rgba(227, 232, 235, 0.5);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 25px;
    height: 100px;
    gap: 6px;
    border-radius: 5px;

    div.popup {
      position: absolute;
      top: 100px;
      right: 66px;
      background-color: white;
      border: 1px solid #ccc;
      z-index: 100;
      border-radius: 10px;

      @media (max-width: 1024px) {
        right: 44px;
      }

      @media (max-width: 576px) {
        top: 70px;
        right: 26px;
      }

      .popup-sidebar {
        display: none;
        flex-direction: column;
        margin: 0;
        padding: 0;

        @media (max-width: 576px) {
          display: flex;
        }
      }

      .popup-item {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;

        p {
          margin-right: 10px;
          margin-bottom: 0;
        }

        svg {
          widows: 20px;
          height: 20px;
          margin-right: 10px;
        }

        &:hover,
        &:focus {
          background-color: #176AD6;

          svg {
            color: white;
          }

          p {
            color: white;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      height: 150px;
      gap: clamp(0.75rem, -1.563vw + 1.063rem, 0.5rem);

      .input-wrapper[data-closeim] {
        width: 100%;
      }

      .user-wrapper {
        width: 100%;

        .user__content {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    svg {
      width: 30px;
      height: 30px;
    }

    & .input-wrapper {
      width: 70%;
      position: relative;
      margin: 10px 0;

      @media (max-width: 576px) {
        width: 100%;
      }

      .input-container {
        input {
          border: 1px solid #bbb8b8;
          border-radius: 40px;
          padding: 5px 10px;
          background-color: transparent;
          outline: none;
          width: 100%;
          padding: 10px 20px;
          font-size: clamp(0.875rem, 0.781vw + 0.719rem, 1rem);
        }

        .input-options-wrapper {
          text-align-last: center;

          position: absolute;

          width: 100%;

          background-color: white;
          border: 1px solid rgb(222, 226, 230);
          border-radius: 10px;

          padding: 0.3rem 1rem;
          margin-top: 0.7rem;
          transition: all 0.3s ease-in-out;

          z-index: 1;

          max-height: 10.8rem;
          overflow-y: auto;

          .files-list-item-wrapper {
            display: flex;
            justify-content: space-between;

            font-size: 15px;
            width: 100%;

            border-bottom: 1px solid rgb(222, 226, 230);

            padding: 3px 5px;

            cursor: pointer;
            transition: all 0.1s ease-in-out;

            white-space: nowrap;

            .files-list-item-name {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 78%;
            }

            &:hover {
              background-color: rgb(222, 226, 230);

              .files-list-item-wrapper {
                border-bottom: 1px solid white;
              }
            }
          }

          .files-list-item-wrapper:last-child {
            border-bottom: 0;
          }
        }
      }

      svg {
        width: clamp(1.25rem, 3.906vw + 0.469rem, 1.875rem);
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .user-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;

      .user__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      svg {
        cursor: pointer;
      }

      .user__logo-mobile {
        display: none;

        @media (max-width: 576px) {
          display: block;
          width: 100%;
          max-width: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
        }
      }

      @media (max-width: 576px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user__data-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        & > div {
          position: relative;

          span {
            font-weight: bold;
            height: min-content;
            white-space: nowrap;
          }

          p {
            font-size: 11px;
            color: #bbb8b8;
            font-weight: bold;
            position: absolute;
            top: 0;
            margin: clamp(0.75rem, 0.568vw + 0.636rem, 1.063rem) 0 0;
          }
        }

        @media (max-width: 1024px) {
          & .user__settings {
            display: none;
          }
        }
      }

      #profileP-container {
        width: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
        height: clamp(2.5rem, 7.813vw + 0.938rem, 3.75rem);
        border: solid rgb(96, 132, 190) 3px;
        background-color: white;
        padding: 1px;

        img {
          border-radius: 50%;
        }

        pointer-events: none;

        @media (max-width: 1024px) {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
  }

  .sidebar {
    width: 25%;
    max-width: 220px;
    position: relative;
    background-color: rgba(227, 232, 235, 0.5);
    height: 100vh;
    text-align: center;
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 768px) {
      justify-content: space-between;
    }

    &__logo {
      width: 100%;
      max-width: clamp(6.25rem, 13.889vw + 2.083rem, 12.5rem);

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__logo-no-text {
      display: none;
      max-width: clamp(3.75rem, 15.625vw - 1.875rem, 5.625rem);

      @media (max-width: 768px) {
        display: block;
        margin: 0 auto;
      }
    }

    &__logo-desc {
      font-size: clamp(0.875rem, 1.042vw + 0.5rem, 1rem);
      margin-top: clamp(0.5rem, 6vw - 1.63rem, 1.25rem);
      color: gray;
    }

    a {
      display: block;
      width: 100%;
    }

    ul {
      text-align: left;
      padding: 0;
      margin-left: 10px;

      li {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-bottom: 5px;

        @media (max-width: 768px) {
          max-width: 46px;
          height: 100%;
          max-height: 40px;
          margin: 24px auto;
          padding: 6px 0;
          border-radius: 10px;
        }

        &.active,
        &:hover,
        &:focus {
          background-color: #176AD6;

          span {
            color: white;
          }

          svg {
            color: white;
          }
        }

        span {
          margin-left: clamp(0.625rem, 1.389vw + 0.208rem, 1.25rem);
          font-size: 14px;

          @media (max-width: 768px) {
            display: none;
          }
        }

        svg {
          width: 25px;
          height: 25px;
          margin-left: 10px;
        }
      }
    }

    .sign-out {
      bottom: 0;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      border-radius: 10px;
      padding: 10px 10px 0 10px;

      &:hover,
      &:focus {
        background-color: #176AD6;

        svg {
          color: white;
        }

        p {
          color: white;
        }
      }

      svg {
        width: 30px;
        height: 30px;
      }

      p {
        font-size: 12px;
        color: rgb(44, 44, 44);
        margin-bottom: 10px;
      }
    }

    @media (max-width: 576px) {
      display: none;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    min-height: 60px;
    background-color: rgba(227, 232, 235, 0.5);

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  #file-list {
    padding-right: 10px;

    .icon-container {
      position: relative;
      display: inline-block;

      .tooltip-text {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 50%;
        margin-left: -60px; // Adjust to ensure the tooltip is centered

        /* Tooltip arrow */
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }
      }

      &:hover .tooltip-text {
        visibility: visible;
      }
    }

    .file_list_info_icon {
      width: 1.4rem;
      margin-left: 1rem;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      top: 7px;
      right: 1rem;
      cursor: pointer;
    }

    .index-status-column, th.index-status-column {
      display: table-cell; // Ensuring it's displayed by default

      @media (max-width: 768px) { // Adjust this value based on your mobile breakpoint
        display: none; // Hides the column on mobile devices
      }
    }

    .icon-indexed {
      width: 20px; // Set the icon size to fit your design
      height: 20px; // Ensure the icon's height matches its width
      vertical-align: middle; // Align the icon vertically with the adjacent text
      margin-right: 5px; // Optional: Adjust spacing to suit your layout
      color: green; // Set a default color, adjust as needed
    }

    .icon-pending {
      width: 20px; // Set the icon size to fit your design
      height: 20px; // Ensure the icon's height matches its width
      vertical-align: middle; // Align the icon vertically with the adjacent text
      margin-right: 5px; // Optional: Adjust spacing to suit your layout
      color: orange; // Set a default color, adjust as needed
    }

    .icon-error {
      width: 20px; /* Set the icon size to fit your design */
      height: 20px; /* Ensure the icon's height matches its width */
      vertical-align: middle; /* Align the icon vertically with the adjacent text */
      margin-right: 5px; /* Optional: Adjust spacing to suit your layout */
      color: red; /* Set a default color, adjust as needed */
    }

    .icon-not-supported {
      width: 20px; /* Set the icon size to fit your design */
      height: 20px; /* Ensure the icon's height matches its width */
      vertical-align: middle; /* Align the icon vertically with the adjacent text */
      margin-right: 5px; /* Optional: Adjust spacing to suit your layout */
      color: gray; /* Set a default color, adjust as needed */
    }

    // Ensures icon visibility on row hover
    .react-table-lite-row:hover {
      .file_list_info_icon {
        opacity: 1;
      }
    }
  }


  #file-line {
    font-size: clamp(0.875rem, 0.227vw + 0.83rem, 1rem);
    white-space: nowrap;
    margin-bottom: 6px;
    border: none;

    div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    @media (max-width: 768px) {
      grid-template-columns: 7fr 1.5fr 0.5fr;
      grid-template-rows: 1fr;

      div:nth-child(2) {
        display: none;
      }
    }
  }
}
