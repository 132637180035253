.dashboard-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	gap: 10px;
	height: calc(93vh - 150px);
	margin: 0 auto;
	margin-top: 10px;
	padding-right: 10px;
	overflow-y: scroll;

    @media (max-width: 1024px) {
		height: calc(86vh - 150px);
	}

	@media (max-width:768px) {
		grid-template-columns: 1fr;
	}

	h2 {
		font-size: 18px;
		font-weight: bold;
		text-decoration: underline;
		text-decoration-color: #176AD6;
		text-underline-offset: 0.4em;
		margin-bottom: 20px;
		text-align: left;

		@media (max-width:576px) {
			text-align: center;
		}
	}


	&>div {
		background-color: rgba(227, 232, 235, 0.5);
		padding: 15px;
		border-radius: 10px;

		ul {
            margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				margin-bottom: 15px;

				p {
					font-size: 12px;
					color: gray;

					&.message {
						color: black;
						font-size: 12px;
					}
				}
			}
		}
	}

	&>div:nth-child(4) {

		ul {
			margin-bottom: 0;
		}
	}
}